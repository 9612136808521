
<template>
  <gf-base>
    <gf-card title="Product Variants" subtitle="List of Product Variants">
      <template #toolbar>
        <gf-button type="success" @click="print">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M16,17 L16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 L8,17 L5,17 C3.8954305,17 3,16.1045695 3,15 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,15 C21,16.1045695 20.1045695,17 19,17 L16,17 Z M17.5,11 C18.3284271,11 19,10.3284271 19,9.5 C19,8.67157288 18.3284271,8 17.5,8 C16.6715729,8 16,8.67157288 16,9.5 C16,10.3284271 16.6715729,11 17.5,11 Z M10,14 L10,20 L14,20 L14,14 L10,14 Z" fill="#000000"/>
                  <rect fill="#000000" opacity="0.3" x="8" y="2" width="8" height="2" rx="1"/>
                </g>
              </svg>
            </span>
          </template>
          Print
        </gf-button>
      </template>
      <template #header>
        <el-input style="width: 250px" class="mr-4" size="medium" prefix-icon="el-icon-search" placeholder="Search product name, variant name, SKU, or tags" v-model="search"></el-input>
        <span class="f-13 mr-2">Status :</span>
        <el-select size="medium" class="mr-4" v-model="status" @change="statusChange">
          <el-option :value="null" label="All"></el-option>
          <el-option :value="0" label="Inactive"></el-option>
          <el-option :value="1" label="Active"></el-option>
        </el-select>
      </template>
      <gf-table :data="variants" :column-filter="columnFilter" @row-click="readVariant" v-loading="loading">
        <el-table-column v-if="columnFilter[0].show" label="Product Name" min-width="150" prop="product.name">
        </el-table-column>
        <el-table-column v-if="columnFilter[1].show" label="Name" min-width="150" prop="name">
        </el-table-column>
        <el-table-column v-if="columnFilter[2].show" label="SKU" min-width="150" prop="sku">
        </el-table-column>
        <el-table-column v-if="columnFilter[3].show" label="Stock on Hold" min-width="150">
          <template slot-scope="slot">
            <span v-if="slot.row.is_pack">--</span>
            <span v-else>{{ slot.row.stocks.map(s => parseFloat(s.value || 0) - parseFloat(s.commited || 0)).reduce((o, n) => o + n, 0) }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[4].show" label="Commited Stock" min-width="150">
          <template slot-scope="slot">
            <span v-if="slot.row.is_pack">--</span>
            <span v-else>{{ slot.row.stocks.map(s => parseFloat(s.commited || 0)).reduce((o, n) => o + n, 0) }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[5].show" label="Created At" min-width="150" prop="created_at">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[6].show" label="Last Updated" min-width="150" prop="updated_at">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.updated_at) }}
          </template>
        </el-table-column>
      </gf-table>
      <template #footer>
        <gf-pagination :pagination="pagination" @change="getVariants"></gf-pagination>
      </template>
    </gf-card>
  </gf-base>
</template>

<script>
// services
import VariantService from '@/services/v1/Variant'

// Lodash
import { debounce } from 'lodash'

export default {
  data () {
    return {
      // ui
      loading: false,

      columnFilter: [
        { label: 'Product Name', show: true },
        { label: 'Name', show: true },
        { label: 'SKU', show: true },
        { label: 'Stock On Hold', show: true },
        { label: 'Commited Stock', show: true },
        { label: 'Created At', show: true },
        { label: 'Updated At', show: true }
      ],
      status: '',
      search: '',
      filter: {},

      // main
      variants: [],

      // pagination
      pagination: {
        total: 0,
        page: {
          size: 25,
          number: 1
        }
      }
    }
  },
  watch: {
    search: debounce(function (value) {
      if (value === '') {
        delete this.filter.q
      } else {
        this.filter.q = {
          like: value
        }
      }

      // Reset to page 1
      this.pagination.page.number = 1

      this.getVariants()
    }, 400)
  },
  methods: {
    statusChange () {
      // Reset to page 1
      this.pagination.page.number = 1

      if (this.status !== null) {
        this.filter['product.is_active'] = { '=': this.status }
        this.getVariants()
        return
      }

      delete this.filter['product.is_active']
      this.getVariants()
    },

    //
    readVariant (row) {
      if (row.is_pack) {
        this.$router.push({ name: 'product-variant-pack-view', params: { id: row.product.id, vid: row.variant_id, pid: row.id } })
        return
      }
      this.$router.push({ name: 'product-variant-detail', params: { id: row.product.id, vid: row.id } })
    },
    print () {
      this.$router.push({ name: 'variant-print', params: { count: this.total } })
    },

    // main method
    async getVariants () {
      try {
        this.loading = true
        const vrService = new VariantService()
        const response = await vrService.list(this.pagination.page, null, this.filter)
        this.variants = response.data.rows

        if (response.data.count === 0 && response.data.rows.length !== 0) {
        } else this.pagination.total = response.data.count
      } catch (error) {
        this.$error(error)
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    this.vrService = new VariantService()
  },
  mounted () {
    this.getVariants()

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Inventory' },
      { title: 'Variants' }
    ])
  }
}
</script>
